export function isNumberInList(number) {
    const numberList = [
        688045, 688047, 688068, 688095, 688102, 688160, 688176, 688178, 688196,
        688197, 688198, 688199, 688200, 688201, 688202, 688203, 688204, 688205,
        688206, 688207, 688208, 688209, 688210, 688211, 688212, 688213, 688214,
        688215, 688216, 688217, 688218, 688219, 688220, 688221, 688222, 688223,
        688224, 688225, 688226, 688227, 688228, 688229, 688230, 688231, 688232,
        688233, 688234, 688235, 688236, 688237, 688238, 688239, 688240, 688241,
        688242, 688243, 688244, 688245, 688246, 688247, 688248, 688249, 688250,
        688251, 688252, 688253, 688254, 688255, 688256, 688257, 688258, 688259,
        688260, 688261, 688262, 688263, 688264, 688265, 688266, 688267, 688268,
        688269, 688270, 688271, 688272, 688273, 688274, 688275, 688276, 688277,
        688278, 688279, 688280, 688281, 688282, 688283, 688284, 688285, 688286,
        688287, 688288, 688289, 688290, 688291, 688292, 688293, 688294, 688295,
        688296, 688297, 688298, 688299, 688300, 688301, 688302, 688303, 688304,
        688305, 688306, 688307, 688308, 688309, 688310, 688311, 688312, 688313,
        688314, 688315, 688316, 688317, 688318, 688319, 688320, 688321, 688322,
        688323, 688324, 688325, 688326, 688327, 688328, 688329, 688330, 688331,
        688332, 688333, 688334, 688335, 688336, 688337, 688338, 688339, 688340,
        688341, 688342, 688343, 688344, 688345, 688346, 688347, 688348, 688349,
        688350, 688351, 688352, 688353, 688354, 688355, 688356, 688357, 688358,
        688359, 688360, 688361, 688362, 688363, 688364, 688365, 688366, 688367,
        688368, 688369, 688370, 688371, 688372, 688373, 688374, 688375, 688376,
        688377, 688378, 688379, 688380, 688381, 688382, 688383, 688384, 688385,
        688386, 688387, 688388, 688389, 688390, 688391, 688392, 688393, 688394,
        688395, 731551, 731575, 734054,
    ];

    return numberList.includes(parseInt(number));
}
